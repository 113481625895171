export default class Validation {
    static isFilled(value) {
        return !!value;
    }
    static required(value) {
        return Validation.isFilled(value) || "Поле обязательно к заполнению";
    }
    static isEmail(value) {
        const pattern = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
        return pattern.test(value);
    }
    static email(value) {
        return Validation.isEmail(value) || "Поле Email заполнено не верно";
    }
    static isInt(value) {
        return /^[0-9]{6}$/.test(String(value));
    }
    static int(value) {
        return Validation.isInt(value) || "Поле должно иметь числовое значение";
    }
    static validateWithView(form, refs) {
        let hasError = false;
        for (let field of form) {
            if (field.rules === undefined)
                continue;
            if (field.rules.length === 0)
                continue;
            //console.log(field.ref)
            if (Object.prototype.hasOwnProperty.call(refs, field.ref)) {
                if (Array.isArray(refs[field.ref])) {
                    if (refs[field.ref].length === 0) {
                        continue;
                    }
                    for (const item of refs[field.ref]) {
                        if (item.validate(true) === false) {
                            // console.log(`error in ${field.ref}`)
                            hasError = true;
                        }
                    }
                }
                else {
                    if (!refs[field.ref]) {
                        continue;
                    }
                    const isValid = refs[field.ref].validate(true);
                    if (isValid === false) {
                        hasError = true;
                        // console.log(`error in ${field.ref}`)
                    }
                }
            }
        }
        return hasError;
    }
}
